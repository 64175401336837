import React from "react";
import i18n from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta, Schema } from "@/__main__/router.mjs";
import { appURLs, I18N_PLACEHOLDER } from "@/app/constants.mjs";
import { Container } from "@/game-lol/Champion.style.jsx";
import ChampionAbilityVideos from "@/game-lol/components/ChampionAbilityVideos.jsx";
import ChampionAram from "@/game-lol/components/ChampionAram.jsx";
import ChampionArena from "@/game-lol/components/ChampionArena.jsx";
import ChampionHeader from "@/game-lol/components/ChampionHeader.jsx";
import ChampionInsights from "@/game-lol/components/ChampionInsights.jsx";
import MatchupColumns from "@/game-lol/components/ChampionMatchupColumns.jsx";
import ChampionNexusBlitz from "@/game-lol/components/ChampionNexusBlitz.jsx";
import ChampionOneForAll from "@/game-lol/components/ChampionOneForAll.jsx";
import ChampionOverview from "@/game-lol/components/ChampionOverview.jsx";
import ChampionProbuilds from "@/game-lol/components/ChampionProbuilds.jsx";
import ChampionSynergies from "@/game-lol/components/ChampionSynergies.jsx";
import ChampionURF from "@/game-lol/components/ChampionURF.jsx";
import {
  CHAMPIONS,
  CHAMPIONS_KEY_TO_ID,
} from "@/game-lol/constants/champion-classes.mjs";
import ComingSoonComponents from "@/game-lol/constants/champion-coming-soon.mjs";
import {
  QUEUE_SYMBOLS,
  RANK_SYMBOL_TO_STR,
  RANK_SYMBOLS,
  REGION_LIST,
  ROLE_PAIRS,
  ROLE_SYMBOL_TO_STR,
  ROLE_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import type { StaticDataChampion } from "@/game-lol/models/lol-champions.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import { CHAMPION_TABS } from "@/game-lol/routes.mjs";
import {
  getFallbackRole,
  patchDisplay,
} from "@/game-lol/utils/champions-stats-utils.mjs";
import Static from "@/game-lol/utils/static.mjs";
import {
  getQueueDetails,
  QUEUE_SYMBOL_TO_OBJECT,
} from "@/game-lol/utils/symbol-queue.mjs";
import RoleSymbol from "@/game-lol/utils/symbol-role.mjs";
import useStaticChampionByKey from "@/game-lol/utils/use-static-champion-by-id.mjs";
import useChampionFilter from "@/game-lol/utils/useChampionFilter.jsx";
import {
  getCurrentPatchForStaticData,
  getPrereleaseChampionByKey,
  getSearchParamsForChampions,
  getStaticChampionByKey,
  prettyPatch,
} from "@/game-lol/utils/util.mjs";
import PageContainer from "@/shared/PageContainer.jsx";
import ClientOnly from "@/util/ClientOnly.jsx";
import keyInObject from "@/util/key-in-object.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import SymbolMap from "@/util/symbol-map.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TAB_QUEUES = {
  arena: QUEUE_SYMBOLS.arena,
  aram: QUEUE_SYMBOLS.aram,
  probuilds: QUEUE_SYMBOLS.rankedSoloDuo,
  counters: QUEUE_SYMBOLS.rankedSoloDuo,
  synergies: QUEUE_SYMBOLS.rankedSoloDuo,
  build: QUEUE_SYMBOLS.rankedSoloDuo,
  undefined: QUEUE_SYMBOLS.rankedSoloDuo,
  null: QUEUE_SYMBOLS.rankedSoloDuo,
};

function Champion() {
  const {
    parameters: [championKeyOrId, tabKey],
    searchParams,
  } = useRoute();
  const tab = keyInObject(CHAMPION_TABS, tabKey) ? tabKey : "build";

  const {
    lol: { championStats },
  } = useSnapshot(readState);

  const patch = getCurrentPatchForStaticData();

  const championId: number =
    Number.parseInt(championKeyOrId, 10) ||
    CHAMPIONS_KEY_TO_ID[championKeyOrId];
  const championKey: string = CHAMPIONS[championId]?.key || championKeyOrId;

  const queue = TAB_QUEUES[tab] || QUEUE_SYMBOLS.rankedSoloDuo;

  const champion = useStaticChampionByKey(championKey) as StaticDataChampion;
  const isPreReleaseChampion = champion?.prerelease;

  const filters = {
    championId,
    queue: QUEUE_SYMBOL_TO_OBJECT[queue].gql,
    region: searchParams?.get("region") || REGION_LIST[0].datalake,
    comingSoon: isPreReleaseChampion,
    tier:
      searchParams?.get("tier") ||
      RANK_SYMBOL_TO_STR[RANK_SYMBOLS.emeraldPlus].gql,
  };

  const urlParams = getSearchParamsForChampions(false, filters);

  const s = championStats[championId]?.[urlParams.toString()] || [];
  const stats = s instanceof Error ? [] : s;

  /* @ts-ignore */
  const fallbackRole = getFallbackRole(stats);
  const fallbackRoleStr = fallbackRole
    ? ROLE_SYMBOL_TO_STR[fallbackRole].gql
    : null;
  const roleStr =
    searchParams.get("role") &&
    ROLE_SYMBOL_TO_STR[RoleSymbol(searchParams.get("role"))]?.gql;
  const role = roleStr || fallbackRoleStr;

  const duoRole =
    !searchParams.get("duoRole") || searchParams.get("duoRole") === "undefined"
      ? ROLE_SYMBOL_TO_STR[ROLE_PAIRS[RoleSymbol(role)]]?.gql
      : searchParams.get("duoRole");

  const championRoleStats =
    tabKey === "aram"
      ? stats.find((c) => c.individual_position === ROLE_SYMBOLS.all)
      : stats.find(
          (c) => RoleSymbol(c.individual_position) === RoleSymbol(role),
        );

  const { FilterBar, victoryOnly } = useChampionFilter(
    tab,
    { ...champion, stats: championStats, role, duoRole },
    null,
  );

  const activeFilters = {
    ...filters,
    role: queue === QUEUE_SYMBOLS.rankedSoloDuo ? role : null,
    duoRole: queue === QUEUE_SYMBOLS.rankedSoloDuo ? duoRole : null,
  };

  let InnerComponent = null;
  switch (tab) {
    case "aram":
      InnerComponent = (
        <ChampionAram
          championId={championId}
          champion={champion}
          championStats={championRoleStats}
          filters={activeFilters}
        />
      );
      break;
    case "arena":
      InnerComponent = <ChampionArena championId={championId} />;
      break;
    case "nexus-blitz":
      InnerComponent = (
        <ChampionNexusBlitz champion={champion} filters={activeFilters} />
      );
      break;
    case "urf":
      InnerComponent = (
        <ChampionURF champion={champion} filters={activeFilters} />
      );
      break;
    case "one-for-all":
      InnerComponent = (
        <ChampionOneForAll
          champion={champion}
          championStats={championRoleStats}
          filters={activeFilters}
        />
      );
      break;
    case "probuilds":
      InnerComponent = (
        <ChampionProbuilds
          championId={championId}
          role={role}
          victoryOnly={victoryOnly}
        />
      );
      break;
    case "counters":
      InnerComponent = (
        <MatchupColumns
          championId={championId}
          champion={champion}
          urlParams={getSearchParamsForChampions(false, {
            ...filters,
            role,
          }).toString()}
        />
      );
      break;
    case "synergies":
      InnerComponent = (
        <ChampionSynergies
          championId={championId}
          urlParams={getSearchParamsForChampions(true, {
            ...filters,
            role,
            duoRole,
          }).toString()}
        />
      );
      break;
    case "abilities":
      InnerComponent = (
        <>
          <ChampionAbilityVideos champion={champion} />
          <ChampionInsights
            hideAbilitiesLink
            role={null}
            patch={null}
            championId={championId}
            champion={champion}
          />
        </>
      );
      break;
    case "build":
    default: {
      InnerComponent = (
        <ChampionOverview
          championId={championId}
          champion={champion}
          urlParams={getSearchParamsForChampions(
            false,
            activeFilters,
          ).toString()}
          patch={prettyPatch(patch)}
          championStats={championRoleStats}
          filters={activeFilters}
        />
      );
    }
  }

  if (champion?.prerelease) {
    const ComingSoon = ComingSoonComponents[champion.key.toLowerCase()];
    return (
      <PageContainer>
        <ChampionHeader
          championId={championId}
          championKey={championKey}
          championName={champion?.name}
          tab={tab}
          filters={activeFilters}
        />
        <div className={Container()}>
          {ComingSoon && <ComingSoon championName={champion.name} />}
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <ChampionHeader
        championId={championId}
        championKey={championKey}
        championName={champion?.name}
        championStats={
          championRoleStats
            ? {
                ...championRoleStats,
                strong_against: [...championRoleStats.strong_against],
                weak_against: [...championRoleStats.weak_against],
              }
            : undefined
        }
        tab={tab}
        filters={activeFilters}
      />
      {FilterBar}
      <ClientOnly>
        <div className={Container()}>{InnerComponent}</div>
      </ClientOnly>
    </PageContainer>
  );
}

export function meta(params, search: URLSearchParams): Meta {
  const { t } = i18n;
  const tabId = params[1] as keyof typeof CHAMPION_TABS;
  const tabInfo = CHAMPION_TABS[tabId];

  let title: [string, string];
  let description: [string, string];
  let subtitle: [string, string];

  const patch = getCurrentPatchForStaticData();

  const championKeyOrId = params[0];
  const championId =
    Number.parseInt(championKeyOrId, 10) ||
    CHAMPIONS_KEY_TO_ID[championKeyOrId];
  const championKey = CHAMPIONS[championId]?.key || championKeyOrId;
  const champion =
    getStaticChampionByKey(championKey, patch) ||
    getPrereleaseChampionByKey(championKey);

  const championStats = readState.lol.championStats;
  const urlParams = getSearchParamsForChampions(false, {
    championId,
    queue: QUEUE_SYMBOL_TO_OBJECT[QUEUE_SYMBOLS.rankedSoloDuo].gql,
    region: REGION_LIST[0].datalake,
    tier: RANK_SYMBOL_TO_STR[RANK_SYMBOLS.emeraldPlus].gql,
  });
  const s = championStats[championId]?.[urlParams.toString()] || [];
  const stats = s instanceof Error ? [] : s;
  const fallbackRole = getFallbackRole(stats);
  const fallbackRoleStr = fallbackRole
    ? ROLE_SYMBOL_TO_STR[fallbackRole].gql
    : null;

  const tier = search.get("tier");

  const queue =
    QUEUE_SYMBOL_TO_OBJECT[tabInfo.queue]?.gql ||
    QUEUE_SYMBOL_TO_OBJECT[QUEUE_SYMBOLS.rankedSoloDuo].gql;
  const hideRole = !!getQueueDetails(queue)?.hideRole;
  const roleString = search.get("role") || fallbackRoleStr;

  const role = hideRole
    ? undefined
    : ROLE_SYMBOL_TO_STR[RoleSymbol(roleString)];

  const rank = SymbolMap.entries(RANK_SYMBOL_TO_STR).find(([_, { gql }]) =>
    tier ? tier === gql : true,
  )?.[1]?.t;

  switch (tabId) {
    case "abilities":
      title = [
        "lol:meta.champion.urf.title",
        "{{championName}} Abilities and Passive.",
      ];
      description = [
        "lol:meta.champion.trends.description",
        "LoL {{championName}} champion abilities breakdown and information.",
      ];
      subtitle = [
        "lol:meta.champion.abilities.subtitle",
        "Optimize your gameplay with our extensive guide to {{championName}}'s abilities. From passive to ultimate, our detailed guide covers everything you need to become a dominant force with {{championName}}.",
      ];
      break;
    case "urf":
      title = [
        "lol:meta.champion.urf.title",
        "{{championName}} URF Builds and Runes.",
      ];
      description = [
        "lol:meta.champion.urf.description",
        "The {{championName}} build for URF. This LoL {{championName}} guide for URF on patch {{patch}} includes runes, items, skill order, guide, and trends.",
      ];
      subtitle = [
        "lol:meta.champion.urf.subtitleNamePatch",
        "{{championName}} with Blitz's best data for every build. The highest win rate {{championName}} build, from rune set to skill order to item path, for URF. LoL Patch {{patch}}",
      ];
      break;
    case "nexus-blitz":
      title = [
        "lol:meta.champion.nexusBlitz.title",
        "{{championName}} Nexus Blitz Builds and Runes.",
      ];
      description = [
        "lol:meta.champion.nexusBlitz.description",
        "The {{championName}} build for Nexus Blitz. This LoL {{championName}} guide for Nexus Blitz on patch {{patch}} includes runes, items, skill order, guide, and trends.",
      ];
      subtitle = [
        "lol:meta.champion.nexusBlitz.subtitleNamePatch",
        "{{championName}} with Blitz's best data for every build. The highest win rate {{championName}} build, from rune set to skill order to item path, for URF. LoL Patch {{patch}}",
      ];
      break;
    case "arena":
      title = [
        "lol:meta.champion.arena.title",
        "{{championName}} Arena Builds, Augments, and Synergies.",
      ];
      description = [
        "lol:meta.champion.arena.description",
        "Discover the ultimate guide to {{championName}} in League of Legends' new Arena mode at Blitz.gg. Stay tuned for the best Arena builds, gameplay strategies, and tips!",
      ];
      subtitle = [
        "lol:meta.champion.arena.subtitleNamePatch",
        "{{championName}} with Blitz's best data for every build. The highest win rate {{championName}} build, from rune set to skill order to item path, for Arena. LoL Patch {{patch}}",
      ];
      break;
    case "aram":
      title = [
        "lol:meta.champion.aram.title",
        "{{championName}} ARAM Build, Runes, Items, and Skill Guide",
      ];
      description = [
        "lol:meta.champion.aram.description",
        "{{championName}} Patch {{patch}} ARAM builds including Runes, Items, Skill Order, and Summoner Spells.",
      ];
      subtitle = [
        "lol:meta.champion.aram.subtitleNamePatch",
        "{{championName}} with Blitz's best data for every build. The highest win rate {{championName}} build, from rune set to skill order to item path, for ARAM. LoL Patch {{patch}}",
      ];
      break;
    case "one-for-all":
      title = [
        "lol:meta.champion.oneForAll.title",
        "{{championName}} One For All Build, Runes, Items, and Skill Guide",
      ];
      description = [
        "lol:meta.champion.oneForAll.description",
        "{{championName}} Patch {{patch}} One For All builds including Runes, Items, Skill Order, and Summoner Spells.",
      ];
      subtitle = [
        "lol:meta.champion.oneForAll.subtitle",
        "{{championName}} with Blitz's best data for every build. The highest win rate {{championName}} build, from rune set to skill order to item path, for One For All. LoL Patch {{patch}}",
      ];
      break;
    case "counters":
      title = [
        "lol:meta.champion.counters.title",
        "{{championName}} Counters & Matchups",
      ];
      description = [
        "lol:meta.champion.counters.description",
        "{{championName}} Counters Matchups. Who counters {{championName}}? Best & Worse {{championName}} matchup.",
      ];
      subtitle = [
        "lol:championNameCountersSubtitle",
        "Discover the ultimate counters for {{championName}} with our comprehensive guide. Learn who counters {{championName}} in lane and throughout the game, turning the tides in your favor. Explore effective {{championName}} counters and strategies to outplay your opponent.",
      ];
      break;
    case "synergies":
      title = [
        "lol:meta.champion.synergies.title",
        "{{championName}} Synergies",
      ];
      description = [
        "lol:meta.champion.synergies.description",
        "{{championName}} Synergies. Best {{championName}} synergies for {{role}}.",
      ];
      subtitle = [
        "lol:championNameSynergiesSubtitle",
        "Discover the best champion synergies with {{championName}} with our comprehensive guide. Learn who synergizes with {{championName}} in lane and throughout the game, turning the tides in your favor.",
      ];
      break;
    case "probuilds":
      title = [
        "lol:meta.champion.probuilds.title",
        "{{championName}} Pro Builds, Guides, Stats, Runes, Items",
      ];
      description = [
        "lol:meta.champion.probuilds.description",
        "{{championName}} Probuilds, builds, guides, stats, top pros, runes, best players, updated in real time.",
      ];
      subtitle = [
        "lol:meta.champion.probuilds.subtitleNamePatch",
        "{{championName}} with Blitz's best Pro Build data for every build. The highest win rate {{championName}} build, from rune set to skill order to item path, for {{role}}. LoL Patch {{patch}}",
      ];
      break;
    default:
      title = [
        "lol:meta.champion.default.title",
        "{{championName}} Builds - Runes, Items, Matchups, and more - Patch {{patch}}",
      ];
      description = [
        "lol:meta.champion.ranked.description",
        "{{championName}} Patch {{patch}} builds including Runes, Items, Skill Order, and Summoner Spells.",
      ];
      subtitle = [
        "lol:meta.champion.build.subtitleNameRolePatch",
        "{{championName}} with Blitz's best data for every build. The highest win rate {{championName}} build, from rune set to skill order to item path, for {{role}}. LoL Patch {{patch}}",
      ];
      break;
  }

  if (champion?.prerelease) {
    title = [
      "lol:meta.champion.prerelease.title",
      "{{championName}} League of Legends Stats, Builds, Guides, Runes, and Items coming soon!",
    ];
    description = [
      "lol:meta.champion.prerelease.description",
      "{{championName}}, League of Legends latest champion coming soon!",
    ];
    subtitle = [
      "lol:meta.champion.prerelease.title",
      "{{championName}} League of Legends Stats, Builds, Guides, Runes, and Items coming soon!",
    ];
  }

  const transParams = {
    champion: champion?.name || CHAMPIONS[championId]?.key || I18N_PLACEHOLDER,
    championName:
      champion?.name || CHAMPIONS[championId]?.key || I18N_PLACEHOLDER,
    role: role ? t(role.label[0], role.label[1]) : I18N_PLACEHOLDER,
    rank: rank ? t(rank.name, rank.fallback) : I18N_PLACEHOLDER,
    patch: patchDisplay(patch, true),
    interpolation: { escapeValue: false },
  };

  return {
    title: [...title, transParams],
    description: [...description, transParams],
    subtitle: subtitle ? [...subtitle, transParams] : undefined,
    schema: champion ? getChampionSchema(champion) : undefined,
    image: {
      url: Static.getChampionSplashImage(championKey),
      alt: ["", champion?.name],
      width: 1280,
      height: 720,
    },
  };
}

function getChampionSchema(champion): Schema {
  const key = champion.key.toLowerCase();

  return {
    "@type": "Person",
    "@id": `${appURLs.BLITZ}/${lolRefs.lolChampionPrefix}/${key}`,
    url: `${appURLs.BLITZ}/${lolRefs.lolChampionPrefix}/${key}`,

    name: champion.name,
    givenName: champion.name,
    disambiguatingDescription: i18n.t(
      "lol:seo.champion.disambiguatingDescription",
      "{{championName}} is a champion in League of Legends.",
      {
        championName: champion.name,
      },
    ),

    description: i18n.t(`lol:seo.champion.${key}.description`, "") || undefined,

    image: Static.getChampionImage(champion.id),
  };
}

export default Champion;
